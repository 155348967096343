<template>
  <div>
    <!-- 导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>工作管理</el-breadcrumb-item>
      <el-breadcrumb-item>工资管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片试图 -->
    <el-tabs v-model="activeName" @tab-click="handleClickTab">
      <el-tab-pane label="工资管理" name="first" :key="'first'">
        <el-card>
          <!-- 查询区域 -->
          <div>
            <el-form :model="queryParams" layout="inline" :inline="true">
              <el-row :gutter="24">
                <el-col :md="12" :sm="8">
                  <el-form-item label="记录月份">
                    <el-input
                      placeholder="请输入"
                      v-model="queryParams.recordData"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="12" :sm="8">
                  <el-form-item label="所属公司">
                    <el-input
                      placeholder="请输入"
                      style="width: 60%"
                      v-model="queryParams.userCompany"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :md="6" :sm="24" style="width: 70%">
                  <el-button type="primary" @click="getSalaryList()"
                    >搜索</el-button
                  >
                  <el-button type="primary" @click="primaryRest()"
                    >重置</el-button
                  >
                </el-col>
              </el-row>
            </el-form>
          </div>

          <!-- 按钮区 -->
          <el-row :gutter="20" style="margin: 9px 2px">
            <el-button
              type="success"
              icon="el-icon-add"
              size="min"
              @click="addButton"
              >新增</el-button
            >
            <el-button
              type="success"
              icon="el-icon-edit"
              size="min"
              @click="editButton"
              >修改</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-edit"
              size="min"
              @click="noButton"
              disabled=""
              >删除</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-edit"
              size="min"
              @click="noButton"
              disabled
              >详情</el-button
            >
          </el-row>
          <!-- 列表区域 -->
          <el-table :data="salaryList" border stripe show-summary>
            <el-table-column label="序号" align="center" width="65">
              <template slot-scope="scope">
                <el-radio
                  :label="scope.$index"
                  v-model="radio"
                  @change.native="getCurrentRow(scope.row)"
                ></el-radio>
              </template>
            </el-table-column>
            <el-table-column label="记录月份" prop="recordData" />
            <el-table-column label="工资月份" prop="salartData" />
            <el-table-column label="额外收入" prop="recordMonth" />
            <el-table-column label="是否全部收入" prop="isCount" />
            <el-table-column label="是否已经全部使用" prop="isUser" />
            <el-table-column label="子表状态" prop="datailsStatus" />
            <el-table-column label="备注" prop="remark" />
            <el-table-column label="状态" prop="status" />
          </el-table>
          <pagination
            :total="queryParams.total"
            :page-size.sync="queryParams.pageSize"
            :current-page.sync="queryParams.currentPage"
          />
        </el-card>
      </el-tab-pane>

      <!-- 基金计划表 -->
      <el-tab-pane label="处理详情" ref="child2" name="second" :key="'second'">
        <child2></child2>
      </el-tab-pane>
    </el-tabs>
    <addSalary ref="addSalaryFrom"></addSalary>
  </div>
</template>

<script>
import salaryInfo from "./salaryInfo";
import addSalary from "./modules/AddSalary";
import { listApi } from "@/api/work/salary/salary";
export default {
  name: "salary",
  components: {
    child2: salaryInfo,
    addSalary,
  },
  data() {
    return {
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
        recordData: "",
        userCompany: "",
      },
      queryInfo: {
        query: "",
        //当前页数
        pagenum: 1,
        pagesize: 2,
      },
      queryAll: {},
      salaryList: [],
      total: 1,
      toggleSearchStatus: false,
      activeName: "first",
      id: null,
      radio: "",
    };
  },
  created() {
    this.getSalaryList();
  },
  watch: {
    "queryParams.currentPage"(val) {
      this.getSalaryList();
    },
    "queryParams.pageSize"(val) {
      this.getSalaryList();
    },
  },
  methods: {
    getSalaryList() {
      listApi(this.queryParams).then((res) => {
        this.salaryList = res.result.records;
        this.queryParams.total = res.result.total;
      });
    },
    getCurrentRow(row) {
      // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
      this.id = row.id;
      this.templateSelection = row;
    },
    handleSizeChange(newSize) {
      console.log(newSize);
      this.queryInfo.pagesize = newSize;
      this.getSalaryList();
    },
    handleCurrentChange(newPage) {
      console.log(newPage);
      this.queryInfo.pagenum = newPage;
      this.getSalaryList();
    },
    handleToggleSearch() {
      this.toggleSearchStatus = !this.toggleSearchStatus;
      console.log(this.toggleSearchStatus);
    },
    handleClickTab(tab, event) {
      console.log(tab, event);
    },
    addButton() {
      this.$refs.addSalaryFrom.show(this.id, 1);
    },
    editButton() {
      if (this.id == null) return this.$message.error("请选择有效的一列");
      this.$refs.addSalaryFrom.show(this.id, 2);
    },
    noButton() {
      this.$message.error("暂未开放");
    },
  },
};
</script>

<style lang="less" scoped>
</style> 